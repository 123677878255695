import React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import "../fonts/fonts.css";
import "./how-oww-works.scss";

// import Button from "../components/button/button"
// import SectionHeading from "../components/section-heading/section-heading"
import ContactSection from "../components/contact-section/contact-section";
import CappedWidth from "../components/capped-width/capped-width";
import ParagraphWithImage from "../components/paragraph-with-image/paragraph-with-image";

//Import images from image.js here:
// import { EllenWreath } from "../components/image"
import submitWriting from "../images/oww-submit-writing.png";
import editorsChoices from "../images/oww-editors-choices.png";
import lists from "../images/oww-lists.png";
import libraryShelves from "../images/oww-library-shelves.png";
import telltales from "../images/oww-telltales.png";
import reviewing from "../images/oww-reviewing.png";
import writersResources from "../images/oww-writers-resources.png";

const writersResourcesDescription = (
  <div>
    We maintain a list of useful online resources for aspiring writers,
    including organizations, other workshops, writers' publications, advice, and
    contests. New resources are sometimes announced in the monthly newsletter.
    For more information and answers to specific questions, read the{" "}
    <a className="paragraph-link" href="/FAQ.shtml">
      FAQ
    </a>
    .
  </div>
);
const HowOwwWorksPage = () => (
  <Layout>
    <SEO title="How OWW Works" />
    <div id="how-oww-works">
      {/* Why Join OWW */}
      <div className="section how-oww-works">
        <CappedWidth>
          <h1 className="page-title">How It Works</h1>
          <h3 className="page-subtitle">
            Our members have made their first sales, made their careers, formed
            mutual critique groups, and even gotten married to each other!
          </h3>
        </CappedWidth>
      </div>

      <ParagraphWithImage
        title="Submit your writing"
        description="After becoming a member, you may submit your work, review the work of others, and participate in workshop discussions. Other members will also be able to read, rate, and review the work you submit. Since reviews and ratings are what improve people's writing, we require that you contribute reviews in order to be allowed to post your own work. (Of course reviews help the submission's author, but our members report that reviewing also helps the reviewer improve his or her own writing by improving analytic skills.) And because we want all members to have a good chance of getting their work reviewed, we have a limit on how many pieces any member can submit at once. "
        image={submitWriting}
      />

      <ParagraphWithImage
        reversed
        title="Editors' Choices"
        description="Each month our Resident Editors select one submission in each of four categories as an Editors' Choice and review it in the newsletter. Editors' Choices do not count against a member's three-active-submission limit, and remain on display in our Editors' Choice area for about four months. (Authors may, of course, still remove these submissions from the workshop, in which case they will no longer be listed in the Editors' Choice area.) If your submission is selected as an Editors' Choice, you will receive an email message from the workshop asking you to keep your submission up until the next newsletter is published, so others can read your submission and its professional review."
        firstButton="Our Resident Editors"
        firstButtonPath="/who-we-are#resident-editors"
        image={editorsChoices}
      />

      <ParagraphWithImage
        title="Lists"
        description="You can create lists on the OWW site to help you manage your workshop participation. You can create as many lists as you want, name them, and put them in the order you want on your Lists page (accessible from your Dashboard). Use a list to keep track of your favorite writers or the submissions you plan to review. Create a list for the members of your reviewing group so you can have easy access to their directory listings. Collect reviews you want to read and learn from. Save submission-selector searches you perform often. To add something to a list, just click on the clipboard symbol."
        image={lists}
      />

      <ParagraphWithImage
        reversed
        title="Your Library Shelves"
        description="Inactive (old, un-updated) submissions are eventually moved automatically to your Library Shelves, where they are read-only and cannot be reviewed. You can move active submissions to your Library Shelves whenever you want. You can also post your work directly to your Library Shelves. You can keep as many submissions as you like in your Library Shelves; other members can find them and read them (but not add reviews). You can organize your Shelves however you like. A submission can be moved back into active status (unshelved) whenever you have a submission slot available; if it has never been active before, you will also need to have review points available to use. Your Library Shelves are accessible via your Dashboard; other members can access your Library Shelves via your listing in the Member Directory."
        image={libraryShelves}
      />

      <ParagraphWithImage
        title="Telltales"
        description="Telltales are opt-in email messages sent to you from the workshop whenever a certain member submits writing or contributes a review, or whenever your submissions are reviewed. You can sign up for submission telltales when you read a submission or in the Member Directory (at the listing for the author you're interested in). Sign up for review telltales in the Member Directory or on the member's Reviewer History page. Sign up for telltales on your own submission on your Telltales page, accessible from your Dashboard. Watch for the bird symbol (as in 'a little bird told me')."
        image={telltales}
      />

      <ParagraphWithImage
        reversed
        title="Reviewing and the Reviewer Honor Roll"
        description="Members who contribute a lot of substantive reviews are acknowledged with a 'busy bee' symbol Members get the black bee after 50 reviews, the blue bee at 150 reviews, and the gold bee at 350 reviews; gold-bee reviewers are definite veterans (we award them the title Master Reviewers). Members who receive especially helpful reviews can nominate the reviewers for the monthly Reviewer Honor Roll, a listing of reviews/reviewers that is updated monthly in each month's newsletter. Use the nomination form in the Reviewer Honor Roll area or the nomination link at the bottom of each review. "
        image={reviewing}
      />

      <ParagraphWithImage
        title="Writers' Resources"
        description={writersResourcesDescription}
        image={writersResources}
      />

      <ContactSection />
    </div>
  </Layout>
);

export default HowOwwWorksPage;
